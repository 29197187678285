import { Navigate, Route, Routes } from "react-router-dom";

import {
  UserRoleActions,
  permissionGroupAccessConstants,
  permissionAccessConstants,
} from "@idsk/components-props";
import { UserAccessWrapper } from "@idsk/components-ui";
import { QuestionFormPage } from "./codeIde";
import { attachHandlers } from "@idsk/ui-core-framework";
import {
  useQuestionAddFormDataFetcher,
  QuestionFormProps,
  useQuestionViewFormDataFetcher,
} from "./codeIde/QuestionForm.df";

import {
  AssessmentSessionDataFetcher,
  AssessmentSessionLayoutProps,
} from "./codeIde/CodeIdeReports/DataFetchers/AssessmentSession.df";
import { lazy } from "react";

const CodeIdeReports = lazy(() =>
  import("./codeIde").then((module) => ({ default: module.CodeIdeReports }))
);

const QuestionBankRoutes = lazy(() =>
  import("@idsk/questionbank").then((module) => ({
    default: module.QuestionBankRoutes,
  }))
);

const AssessmentRoutes = lazy(() =>
  import("@idsk/assessment").then((module) => ({
    default: module.AssessmentRoutes,
  }))
);

const AssessmentAdministrationRoutes = lazy(() =>
  import("@idsk/assessment-administration-app").then((module) => ({
    default: module.AssessmentAdministrationRoutes,
  }))
);

const UserGuideRoutes = lazy(() =>
  import("@idsk/userguide-app").then((module) => ({
    default: module.UserGuideRoutes,
  }))
);

const CampusAdministrationRoutes = lazy(() =>
  import("@idsk/campus-administration-app").then((module) => ({
    default: module.CampusAdministrationRoutes,
  }))
);

const StudentDataRoutes = lazy(() =>
  import("@idsk/candidate-student").then((module) => ({
    default: module.StudentDataRoutes,
  }))
);

const CandidateGroupRoutes = lazy(() =>
  import("@idsk/candidate-group").then((module) => ({
    default: module.CandidateGroupRoutes,
  }))
);

const LmsRouter = lazy(() =>
  import("@idsk/lms-web-app").then((module) => ({ default: module.LmsRouter }))
);

const AdminRouter = lazy(() =>
  import("@idsk/admin-web-app").then((module) => ({
    default: module.AdminRouter,
  }))
);

const UserGroupRoutes = lazy(() =>
  import("@idsk/user-group-app").then((module) => ({
    default: module.UserGroupRoutes,
  }))
);

const UserManagementRoutes = lazy(() =>
  import("@idsk/user-management-app").then((module) => ({
    default: module.UserManagementRoutes,
  }))
);

const AssessmentDashboardRoutes = lazy(() =>
  import("@idsk/assessment-admin-dashboard").then((module) => ({
    default: module.AssessmentDashboardRoutes,
  }))
);

const CandidateReportRoutes = lazy(() =>
  import("@idsk/candidate-report").then((module) => ({
    default: module.CandidateReportRoutes,
  }))
);

// import { CodeIdeReports } from "./codeIde";

// Existing layouts for QuestionForm
const QuestionFormLayout = attachHandlers<QuestionFormProps>(
  "QuestionFormLayout"
)(useQuestionAddFormDataFetcher)(QuestionFormPage);

const QuestionViewLayout = attachHandlers<QuestionFormProps>(
  "QuestionViewLayout"
)(useQuestionViewFormDataFetcher)(QuestionFormPage);

const CodeIdeReportsLayout = attachHandlers<
  AssessmentSessionLayoutProps,
  { type: string; readonly?: boolean }
>("CodeIdeReportsLayout")(AssessmentSessionDataFetcher)(CodeIdeReports);

export const RootRouter = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={"/dashboard"} replace={true} />} />
      <Route path="*" element={<Navigate to={"/dashboard"} replace={true} />} />
      <Route path="/dashboard" element={<AssessmentDashboardRoutes />} />
      {/* Specific route for adding a new question */}
      <Route
        path="/clients/:clientId/questions/FRAMEWORK/add"
        element={<QuestionFormLayout />}
      />

      {/* Specific route for viewing/editing a question */}
      <Route
        path="/clients/:clientId/questions/FRAMEWORK/:id"
        element={<QuestionViewLayout />}
      />

      <Route
        path="/clients/:clientId/questions/*"
        element={<QuestionBankRoutes />}
      />

      {/* Other routes */}
      <Route path="/users/*" element={<StudentDataRoutes />} />
      <Route
        path="/clients/:clientId/assessments/*"
        element={<AssessmentRoutes />}
      />
      <Route path="/groups/*" element={<CandidateGroupRoutes />} />
      <Route
        path="/clients/:clientId/assessmentadministrations/*"
        element={<AssessmentAdministrationRoutes />}
      />
      <Route path="/userguide/*" element={<UserGuideRoutes />} />
      <Route
        path="/clients/:clientId/students/*"
        element={<CampusAdministrationRoutes />}
      />
      <Route path="/lms/*" element={<LmsRouter />} />
      <Route path="/admin/*" element={<AdminRouter />} />
      <Route
        path="/manage/users/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.userManagement}
            policyName={permissionAccessConstants.users}
          >
            <UserManagementRoutes />
          </UserAccessWrapper>
        }
      />

      {/* Updated route for CodeIdeReportsLayout */}
      <Route
        path="/clients/:clientId/assessment-reports/:id/codeIde"
        element={<CodeIdeReportsLayout type="sessionId" readonly={true} />}
      />
      <Route
        path="/manage/college/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.general}
            policyName={permissionAccessConstants.organizations}
          >
            <UserGroupRoutes />
          </UserAccessWrapper>
        }
      />
      <Route
        path="/manage/candidate-reports/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.reports}
            policyName={permissionAccessConstants.reports}
          >
            <CandidateReportRoutes />
          </UserAccessWrapper>
        }
      />

      <Route
        path="/manage/groups/*"
        element={
          <UserAccessWrapper
            permission={UserRoleActions.View}
            policyGroupName={permissionGroupAccessConstants.userManagement}
            policyName={permissionAccessConstants.groups}
          >
            <UserGroupRoutes />
          </UserAccessWrapper>
        }
      />
    </Routes>
  );
};
