import { useContext, useEffect, useRef, useState } from "react";
import { ArrowLeft } from "@idsk/components-assets";
import {
  Col,
  Button,
  Space,
  Typography,
  Row,
  Drawer,
  Form,
  message,
  Spin,
} from "antd";
import {
  QuestionCreator,
  IdskButton,
  AsyncGetAndPatchUI,
} from "@idsk/components-ui";
import { useNavigate, useParams } from "react-router";
import {
  QuestionBankTypeOption,
  QuestionType,
  Category,
  OtherTag,
  Difficulty,
  Duration,
  AptitudeTopics,
  LogicalReasoningTopics,
  ProgrammingTopics,
  AptitudeConcepts,
  ProgrammingConcepts,
  BehaviouralTopics,
  PsychometricTopics,
  Others,
  ReturnType,
  LanguageEnum,
  QuestionParameterOptions,
  TechnicalTopics,
} from "@idsk/components-props";
import {
  getStageType,
  UserContext,
  Entity,
  getLoadableAssetsCampusLinkFromPath,
  StageType,
  getLoadableAssetsLinkFromPath,
} from "@idsk/ui-core-framework";
import CreateQuestionForm from "./CreateQuestionForm";
import { SandpackProvider } from "@codesandbox/sandpack-react";
import {
  QuestionFormProps,
  useSaveQuestionWithCodeTemplates,
} from "./QuestionForm.df";
import JSZip from "jszip";
import axios from "axios";

interface FileState {
  path: string;
  code: string;
  readOnly: boolean;
}

interface FetchedData extends Entity {
  codeTemplatesLink?: string;
  codeTemplates?: FileState[];
  readOnlyFiles?: string[];
  framework?: string;
}

const QuestionFormPage = (props: QuestionFormProps) => {
  const navigate = useNavigate();
  const [selectedFramework, setSelectedFramework] = useState<string>("react");
  const [previewQuestionEntity, setPreviewQuestionEntity] = useState<
    Entity | undefined
  >();
  const submitBtnRef = useRef(null);
  const draftBtnRef = useRef(null);
  const runBtnRef = useRef(null);

  const { clientId: contextClientId } = useContext(UserContext);
  const { id, clientId: questionClientId } = useParams<{
    id: string;
    clientId: string;
  }>();

  let language = LanguageEnum.map((el) => {
    return [el.id, el.name];
  });

  const languageOptions = Object.fromEntries(language);

  const questionParameterOption: QuestionParameterOptions = {
    languageType: languageOptions,
    returnType: ReturnType,
  };

  const [form] = Form.useForm();

  const fetchedData = (
    props.asyncQuestionFormUi as AsyncGetAndPatchUI<
      Partial<FetchedData> & Entity
    >
  )?.getResponse() as (Partial<FetchedData> & Entity) | null;

  const [files, setFiles] = useState<FileState[]>([]);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);

  const initialLanguage: string = fetchedData?.framework || "react";

  useEffect(() => {
    setSelectedFramework(initialLanguage);
  }, [initialLanguage]);

  useEffect(() => {
    async function fetchAndProcessCodeTemplates() {
      try {
        if (!fetchedData?.codeTemplatesLink) {
          setLoadingFiles(false);
          return;
        }
        setLoadingFiles(true);
        const environment =
          getStageType() === StageType.DEVELOPMENT ? "dev" : getStageType();
        const clientIdFinal = props.isEditable
          ? questionClientId!
          : contextClientId!;
        const questionId = fetchedData.id;
        const codeTemplatesS3Prefix = `/assets/idesk-${environment}-assessment/frontendframework/questions/clients/${clientIdFinal}/${questionId}/`;
        const codeTemplatesPath = `${codeTemplatesS3Prefix}codeTemplates.zip`;
        const codeTemplatesUrl =
          getLoadableAssetsLinkFromPath(codeTemplatesPath) ?? "";
        const s3 = await axios.get(codeTemplatesUrl);
        if (!s3.data) {
          message.error("Invalid code templates URL.");
          setLoadingFiles(false);
          return;
        }

        const response = await fetch(s3.data, {
          method: "GET",
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error(
            `Failed to fetch code templates. Status: ${response.status}`
          );
          console.error("Error Response:", errorText);
          throw new Error(
            `Failed to fetch code templates. Status: ${response.status}`
          );
        }

        const zipBlob = await response.blob();
        const zip = await JSZip.loadAsync(zipBlob);
        const fileStates: FileState[] = [];
        const readOnlyFiles = fetchedData.readOnlyFiles || [];
        await Promise.all(
          Object.keys(zip.files).map(async (filename) => {
            const file = zip.files[filename];
            if (!file.dir) {
              try {
                const content = await file.async("string");
                const filePath = filename;
                let readOnly = false;
                if (file.comment) {
                  try {
                    const commentData = JSON.parse(file.comment);
                    readOnly = commentData.readOnly || false;
                  } catch (e) {
                    console.warn(
                      `Failed to parse comment for file ${filename}:`,
                      e
                    );
                  }
                }
                fileStates.push({
                  path: filePath,
                  code: content,
                  readOnly: readOnly,
                });
              } catch (fileError) {
                console.error(`Error reading file ${filename}:`, fileError);
              }
            }
          })
        );
        setFiles(fileStates);
        message.success("Code templates loaded successfully.");
      } catch (error: any) {
        console.error("Error fetching or processing the ZIP file:", error);
        message.error(`Error fetching code templates: ${error.message}`);
      } finally {
        setLoadingFiles(false);
      }
    }

    fetchAndProcessCodeTemplates();
  }, [
    fetchedData?.codeTemplatesLink,
    fetchedData?.codeTemplates,
    fetchedData?.readOnlyFiles,
    props.isEditable,
    questionClientId,
    contextClientId,
  ]);

  const questionOption: QuestionBankTypeOption = {
    questionType: QuestionType,
    category: Category,
    aptitudeTopics: AptitudeTopics,
    technicalTopics: TechnicalTopics,
    psychometricTopics: PsychometricTopics,
    behaviouralTopics: BehaviouralTopics,
    logicalReasoningTopics: LogicalReasoningTopics,
    programmingTopics: ProgrammingTopics,
    programmingConcepts: ProgrammingConcepts,
    aptitudeConcepts: AptitudeConcepts,
    otherTags: OtherTag,
    difficulty: Difficulty,
    duration: Duration,
    others: Others,
  };

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedFramework(newLanguage);
  };

  const saveQuestion = useSaveQuestionWithCodeTemplates();

  const handleSaveTemplate = async (fileStates: FileState[]) => {
    try {
      await form.validateFields();

      const payload = {
        ...form.getFieldsValue(),
        clientId: props.isEditable ? questionClientId! : contextClientId!,
        status: "PUBLISHED",
        framework: selectedFramework,
      };

      await saveQuestion(payload, fileStates, questionClientId);
      localStorage.removeItem("sectionId");
      localStorage.removeItem("sectionStartTime");
      localStorage.removeItem("completedSections");
      message.success("Question and code templates saved successfully!");
      navigate(-1);
    } catch (error: any) {
      console.error("Error saving template:", error);
      message.error("Error saving template! Please try again.");
    }
  };

  return (
    <SandpackProvider theme="dark">
      <props.asyncQuestionFormUi.Wrapper>
        <Drawer
          headerStyle={{ display: "none" }}
          width={window.innerWidth - 200}
          open={!!previewQuestionEntity}
          onClose={() => setPreviewQuestionEntity(undefined)}
          height={window.innerHeight}
        >
          {previewQuestionEntity && (
            <div
              style={{ width: "100%", height: "100%", position: "relative" }}
            >
              <IdskButton
                onClick={() => setPreviewQuestionEntity(undefined)}
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "50px",
                  zIndex: 101,
                }}
              >
                Close
              </IdskButton>
            </div>
          )}
        </Drawer>

        <div
          style={{
            height: "60px",
            backgroundColor: "#f0f2f5",
            padding: "10px 20px",
          }}
        >
          <Space align="center">
            <Button type="text" onClick={() => navigate(-1)}>
              <ArrowLeft />
            </Button>
            <Typography.Title level={4} style={{ margin: 0 }}>
              {id ? "Edit Question" : "Add New Question"}
            </Typography.Title>
          </Space>
        </div>
        <Row
          justify="center"
          style={{ backgroundColor: "white", paddingTop: "20px" }}
        >
          <Col
            xs={{ span: 24 }}
            md={{ span: 20 }}
            style={{ padding: "20px", backgroundColor: "white" }}
          >
            <QuestionCreator
              form={form}
              generateStubCode={(value) => Promise.resolve({})}
              onSubmit={(value) => Promise.resolve({})}
              questionBankOption={questionOption}
              questionParameterOptions={questionParameterOption}
              questionForm={fetchedData || {}}
              createAnotherInstance={(value) => {}}
              onCancel={() => navigate(-1)}
              questionType={"FRAMEWORK"}
              submitBtnRef={submitBtnRef}
              draftBtnRef={draftBtnRef}
              runBtnRef={runBtnRef}
              isEditable={props.isEditable !== false}
              readOnly={false}
              hideStepper={true}
              hideSaveNext={true}
              hideFooter={true}
            />
            {loadingFiles ? (
              <Spin
                tip="Loading code templates..."
                style={{ display: "block", marginTop: "20px" }}
              >
                <div style={{ minHeight: "200px" }} />
              </Spin>
            ) : (
              <CreateQuestionForm
                onSaveTemplate={handleSaveTemplate}
                framework={selectedFramework}
                codeTemplates={files}
                onLanguageChange={handleLanguageChange}
              />
            )}
          </Col>
        </Row>
      </props.asyncQuestionFormUi.Wrapper>
    </SandpackProvider>
  );
};

export default QuestionFormPage;
