import { HomeContainer } from "./HomeContainer";
import { Route, Routes } from "react-router";
import { AssessmentSessionViewRoutes } from "@idsk/assessment-session";
import { AdminRouter } from "@idsk/creya-admin-app";
import { ReportRouter } from "@idsk/creya-report-app";

export const RootContainer = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<HomeContainer />} />
        <Route index element={<HomeContainer />} />
        <Route
          path="/clients/:clientId/assessment-sessions/:id/view/*"
          element={<AssessmentSessionViewRoutes />}
        />
        <Route path="/creya/*" element={<AdminRouter />} />
        <Route path="/report/*" element={<ReportRouter />} />
      </Routes>
    </>
  );
};
